<template>
  <div>
    <div
      v-if="isFilling"
      @click="hideFilling()"
      class="water-container"
      :style="{ height: waterHeight + '%' }"
    >
      <div class="wave-container">
        <div
          class="wave"
          v-for="wave in waves"
          :key="wave.id"
          :style="wave.style"
        ></div>
      </div>
      <div v-show="showMessage" class="water-message center text-center">
        <h1 class="white">{{ $t("messages.birthday.header") }}</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      waterHeight: 0,
      isFilling: false,
      showMessage: false,
      waves: [],
    };
  },
  methods: {
    finalizeAnimation() {
      this.showMessage = true;
    },
    hideFilling() {
      this.isFilling = false;
      this.showMessage = false;
      this.waterHeight = 0;
    },
    startFilling() {
      window.scrollTo(0, 0);
      if (this.isFilling == true) {
        this.hideFilling();
      }

      this.isFilling = true;
      const interval = setInterval(() => {
        if (this.waterHeight < 100) {
          this.waterHeight += 5; // Anpassen der Höhe entsprechend der gewünschten Füllgeschwindigkeit
        } else {
          this.finalizeAnimation();
          clearInterval(interval);
        }
      }, 500); // Intervalldauer anpassen, um die gewünschte Animationsschleife zu erhalten
      this.generateWaves(); // Generiere die Wellen beim Starten der Animation
    },
    generateWaves() {
      const waveCount = 80; // Anzahl der Wellen
      const waveHeight = 40; // Höhe der Wellen in Pixel

      for (let i = 0; i < waveCount; i++) {
        const wave = {
          id: i + "" + Math.random(),
          style: {
            height: waveHeight + "px",
            animationDelay: i * 0.01 + "s", // Verzögerung für jede Welle
          },
        };
        this.waves.push(wave);
      }
    },
  },
};
</script>

<style>
.water-message {
  animation: fadeIn 2s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.wave-container {
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  right: 0;
  height: 40px; /* Höhe der Wellen */
  overflow: hidden;
}
.wave {
  position: absolute;
  left: 0;
  border-radius: 50%;
  top: -20px;
  background-position: top;
  right: 0;
  border-color: transparent;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.4),
    transparent
  ); /* Farbverlauf der Wellen anpassen */
  animation: wave-animation 2s infinite linear; /* Animationsdauer und -effekt anpassen */
}
.water-container {
  z-index: 1000000;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  border: none;
  background-color: rgba(0, 143, 195, 0.6); /* Farbe des Wassers */
  transition: height 0.5s ease; /* Animationsübergangsdauer anpassen */
}

@keyframes wave-animation {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>
