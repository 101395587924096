<template>
  <div>
    <div class="vca-center avatar-container">
      <img :src="avatarURL" />
    </div>
    <br />
    <div v-if="current.avatar.file_id !== ''" class="vca-center">
      <vca-cancel-button
        @click="deleteAvatar()"
        :placeholder="$t('button.delete')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AvatarDisplay",
  computed: {
    ...mapGetters({
      current: "user/current",
    }),
    avatarURL() {
      if (this.current.avatar.file_id !== "") {
        return (
          process.env.VUE_APP_BACKEND_URL +
          "/users/avatar/img/" +
          this.current.avatar.file_id
        );
      } else {
        return "";
      }
    },
  },
  methods: {
    deleteAvatar() {
      this.$store.dispatch({
        type: "user/avatar/delete",
        id: this.current.avatar.id,
      });
    },
  },
};
</script>
