<template>
  <div>
    <vca-row>
      <vca-card class="shadowed">
        <General />
        <Newsletter />
      </vca-card>
      <vca-card class="shadowed">
        <Crew />
        <Address />
      </vca-card>
    </vca-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Newsletter from "@/components/account/settings/Newsletter";
import General from "@/components/account/profile/General";
import Address from "@/components/account/profile/Address";
import Crew from "@/components/account/profile/Crew";
export default {
  name: "AccountProfile",
  components: { Newsletter, General, Address, Crew },
  methods: {
    update() {
      this.$store.dispatch({ type: "user/address/update" });
    },
  },
  computed: {
    ...mapGetters({
      user: "user/current",
    }),
  },
  created() {
    this.$store.dispatch("refresh");
  },
};
</script>
