<template>
  <vca-field :label="$t('profile.edit.address.header')">
    <p>{{ $t("profile.edit.address.search.description") }}</p>
    <vca-column>
      <div>
        <h3>{{ $t("profile.edit.address.search.label") }}</h3>
        <vca-location
          v-model="coe"
          @input="focus()"
          :defaultLang="api_lang"
          ref="location"
          type="address"
        />
      </div>

      <vca-field :label="$t('or')" />
      <div>
        <h3>{{ $t("profile.edit.address.street.label") }}</h3>
        <vca-field-row>
          <vca-input
            first
            v-model="current.street"
            class="long"
            ref="street"
            :rules="$v.current.street"
            :placeholder="$t('profile.edit.address.street.placeholder')"
            :errorMsg="$t('profile.edit.address.street.errorMsg')"
          />
          <vca-input
            class="short"
            last
            v-model="current.number"
            ref="number"
            :rules="$v.current.number"
            :placeholder="$t('profile.edit.address.number.placeholder')"
            :errorMsg="$t('profile.edit.address.number.errorMsg')"
          />
        </vca-field-row>
      </div>
      <div>
        <h3>{{ $t("profile.edit.address.additional.label") }}</h3>
        <vca-input
          v-model="current.additional"
          ref="additional"
          :rules="$v.current.additional"
          :placeholder="$t('profile.edit.address.additional.placeholder')"
          :errorMsg="$t('profile.edit.address.additional.errorMsg')"
        />
      </div>
      <div>
        <h3>{{ $t("profile.edit.address.zip.label") }}</h3>
        <vca-input
          v-model="current.zip"
          ref="zip"
          :rules="$v.current.zip"
          :placeholder="$t('profile.edit.address.zip.placeholder')"
          :errorMsg="$t('profile.edit.address.zip.errorMsg')"
        />
      </div>
      <div>
        <h3>{{ $t("profile.edit.address.city.label") }}</h3>
        <vca-input
          v-model="current.city"
          ref="city"
          :rules="$v.current.city"
          :placeholder="$t('profile.edit.address.city.placeholder')"
          :errorMsg="$t('profile.edit.address.city.errorMsg')"
        />
      </div>
      <div>
        <h3>{{ $t("profile.edit.address.country.label") }}</h3>
        <CountrySelection
          v-model="country"
          ref="country"
          countryCode="DE"
          :preselection="preselection"
          :rules="$v.current.country"
          label=""
          :placeholder="$t('profile.edit.address.country.placeholder')"
          :errorMsg="$t('profile.edit.address.country.errorMsg')"
        />
      </div>
    </vca-column>

    <button class="vca-button" @click="save()">
      {{ $t("button.save") }}
    </button>
    <div v-if="current.id != ''" class="vca-center">
      <vca-cancel-button
        @click="deleteAddress()"
        :placeholder="$t('button.delete')"
      />
    </div>
  </vca-field>
</template>
<script>
import { mapGetters } from "vuex";
import CountrySelection from "@/components/utils/CountrySelection.vue";
export default {
  name: "ProfileAddress",
  components: { CountrySelection },
  data() {
    return {
      coe: null,
      preselection: null,
    };
  },
  computed: {
    current: {
      set(value) {
        this.$store.commit("user/address/current", value);
      },
      get() {
        return this.$store.state.user.address.current;
      },
    },
    country: {
      set(value) {
        if (value.length) {
          this.current.country_code = value[0].value;
          this.current.country = value[0].title;
        } else {
          this.current.country_code = "";
          this.current.country = "";
        }
      },
      get() {
        return [{ value: this.current.country_code }];
      },
    },
    ...mapGetters({
      api_lang: "api_lang",
      user: "user/current",
      validation: "user/address/validations",
      hasAddress: "user/address/hasAddress",
    }),
  },
  validations() {
    return this.validation;
  },
  watch: {
    api_lang: {
      handler(value) {
        this.$refs.location.setLanguage(value);
      },
    },
    coe: {
      deep: true,
      handler(val) {
        if (val !== null) {
          this.current.street = val.street ? val.street : "";
          this.current.number = val.number ? val.number : "";
          this.current.zip = val.zip ? val.zip : "";
          this.current.city = val.city ? val.city : "";
          this.current.country = val.country ? val.country : "";
          this.current.country_code = val.country_code ? val.country_code : "";
          this.current.meta = val;
          this.preselection = val.country_code ? val.country_code : "";
        }
      },
    },
  },
  methods: {
    focus() {
      this.$refs.number.focusInput();
    },
    deleteAddress() {
      if (!confirm(this.$t("profile.edit.address.delete.confirm"))) {
        return false;
      }
      this.$store
        .dispatch({
          type: "user/address/delete",
          data: this.user.address.id,
        })
        .then(() => {
          this.$store.dispatch("refresh");
        });
    },
    save() {
      this.$refs.street.validate();
      this.$refs.number.validate();
      this.$refs.zip.validate();
      this.$refs.city.validate();
      this.$refs.country.validate();
      if (!this.$v.$invalid) {
        if (this.hasAddress) {
          this.$store.dispatch({ type: "user/address/update" }).then(() => {
            this.$store.dispatch("refresh");
          });
        } else {
          this.$store.dispatch({ type: "user/address/create" }).then(() => {
            this.$store.dispatch("refresh");
          });
        }
      }
    },
  },
};
</script>
