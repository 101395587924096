<template>
  <div>
    <vca-field :label="$t('profile.edit.general.avatar.header')">
      <vca-card>
        <AvatarDisplay />
        <AvatarUpload />
      </vca-card>
    </vca-field>
    <vca-field :label="$t('profile.edit.general.header')">
      <div class="highlight">{{ $t("profile.edit.general.info") }}</div>

      <vca-card class="shadowed dashlet-content">
        <h3>{{ $t("profile.edit.general.name.label") }}</h3>
        <vca-row>
          <div class="vertical-center">
            <span class="bold">{{ user.display_name }}</span>
            ({{ user.first_name }} {{ user.last_name }})
          </div>
          <div class="inline-infobox">
            <vca-info-box>{{
              $t("profile.edit.general.name.info")
            }}</vca-info-box>
          </div>
        </vca-row>
        <h3>{{ $t("profile.edit.general.email.label") }}</h3>
        <vca-row>
          <div class="vertical-center">
            <span class="bold">{{ user.email }}</span>
          </div>
          <div class="inline-infobox">
            <vca-info-box>{{
              $t("profile.edit.general.email.info")
            }}</vca-info-box>
          </div>
        </vca-row>
      </vca-card>
      <div>
        <h3>{{ $t("profile.edit.general.phone.label") }}</h3>
        <vca-input
          v-model="current.phone"
          :errorMsg="$t('profile.edit.general.phone.errorMsg')"
          :rules="$v.current.phone"
          :maxLength="14"
          type="tel"
          ref="phone"
          :placeholder="$t('profile.edit.general.phone.placeholder')"
        />
      </div>
      <div>
        <h3>{{ $t("profile.edit.general.mattermost.label") }}</h3>
        <vca-input
          v-model="current.mattermost_username"
          :placeholder="$t('profile.edit.general.mattermost.placeholder')"
        />
      </div>
      <div>
        <h3>{{ $t("profile.edit.general.birthdate.label") }}</h3>
        <vca-row>
          <vca-input-date
            v-model="current.birthdate"
            :default="openDate"
            :disabledValues="disabledValues"
            ref="birthdate"
            :language="this.$i18n.locale"
            :placeholder="$t('profile.edit.general.birthdate.placeholder')"
          />
          <div
            v-if="current.birthdate"
            class="small-cancel-button input-cancel-button"
          >
            <vca-cancel-button @click="resetDate()" />
          </div>
          <div class="inline-infobox">
            <vca-info-box>{{
              $t("profile.edit.general.birthdate.info")
            }}</vca-info-box>
          </div>
        </vca-row>
      </div>
      <div>
        <h3>{{ $t("profile.edit.general.gender.label") }}</h3>
        <vca-row>
          <div>
            <input
              type="radio"
              v-model="current.gender"
              id="female"
              value="female"
            />
            <label for="female">
              {{ $t("profile.edit.general.gender.female") }}</label
            >
          </div>
          <div>
            <input
              type="radio"
              v-model="current.gender"
              id="male"
              value="male"
            />
            <label for="male">
              {{ $t("profile.edit.general.gender.male") }}</label
            >
          </div>
          <div>
            <input
              type="radio"
              v-model="current.gender"
              id="divers"
              value="divers"
            />
            <label for="divers">
              {{ $t("profile.edit.general.gender.divers") }}</label
            >
          </div>
          <div>
            <input type="radio" v-model="current.gender" id="none" value="" />
            <label for="none">
              {{ $t("profile.edit.general.gender.none") }}</label
            >
          </div>
          <!--div class="inline-infobox">
                        <vca-info-box>{{ $t('profile.edit.general.gender.info') }}</vca-info-box>
                    </div-->
        </vca-row>
      </div>
      <button
        v-if="!current || !current.id"
        :disabled="$v.$invalid"
        class="vca-button"
        @click="create()"
      >
        {{ $t("button.save") }}
      </button>
      <button
        v-else
        :disabled="$v.$invalid"
        class="vca-button"
        @click="update()"
      >
        {{ $t("button.save") }}
      </button>
    </vca-field>
  </div>
</template>

<script>
import AvatarUpload from "@/components/account/profile/AvatarUpload";
import AvatarDisplay from "@/components/account/profile/AvatarDisplay";
import { mapGetters } from "vuex";
export default {
  name: "ProfileGeneral",
  components: { AvatarUpload, AvatarDisplay },
  computed: {
    ...mapGetters({
      validation: "user/profile/validations",
    }),
    current: {
      set(value) {
        this.$store.commit("user/profile/current", value);
      },
      get() {
        return this.$store.state.user.profile.current;
      },
    },
    openDate() {
      return new Date().setFullYear(new Date().getFullYear() - 16) / 1000;
    },
    disabledValues() {
      return {
        from: new Date(),
      };
    },
    user: {
      get() {
        return this.$store.state.user.current;
      },
    },
  },
  validations() {
    return this.validation;
  },
  methods: {
    create() {
      if (this.$v.$invalid) {
        this.$refs.phone.validate();
      } else {
        this.$store.dispatch({ type: "user/profile/create" }).then(() => {
          this.$store.dispatch("refresh");
        });
      }
    },
    update() {
      if (this.$v.$invalid) {
        this.$refs.phone.validate();
      } else {
        this.$store.dispatch({ type: "user/profile/update" }).then(() => {
          this.$store.dispatch("refresh");
        });
      }
    },
    resetDate() {
      if (!confirm(this.$t("profile.edit.birthdate.delete.confirm"))) {
        return false;
      }
      this.$refs.birthdate.clearDate();
    },
  },
};
</script>
<style lang="scss">
.input-cancel-button {
  margin: 0 1em 1em;

  .cancel-button {
    height: 48px;
  }
}
</style>
