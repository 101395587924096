<template>
  <vca-field :label="$t('profile.edit.crew.header')">
    <p v-html="$t('profile.edit.crew.select.description')"></p>
    <vca-column>
      <h3>{{ $t("profile.edit.crew.select.label") }}</h3>
      <vca-dropdown
        v-model="select"
        :options="crewList"
        label=""
        :placeholder="$t('profile.edit.crew.select.placeholder')"
      ></vca-dropdown>
      <button
        v-if="!hasCrew"
        class="vca-button"
        :disabled="select.length == 0"
        @click="create()"
      >
        {{ $t("button.enter") }}
      </button>
      <div v-else>
        <button class="vca-button" @click="leave()" v-if="select.length == 0">
          {{ $t("button.change") }}
        </button>
        <button
          class="vca-button"
          @click="update()"
          v-else
          :disabled="select[0].value == current.crew_id"
        >
          {{ $t("button.change") }}
        </button>
        <div class="vca-center">
          <vca-cancel-button
            :placeholder="
              $t('context_button.leave', {
                0: $t('profile.edit.crew.context'),
              })
            "
            @click="leave()"
          />
        </div>
      </div>
    </vca-column>
  </vca-field>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ProfileCrew",
  computed: {
    select: {
      set(value) {
        this.$store.commit("user/crew/select", value);
      },
      get() {
        return this.$store.state.user.crew.select;
      },
    },
    ...mapGetters({
      crewList: "crews/dropdown",
      hasCrew: "user/crew/hasCrew",
      current: "user/crew/current",
      crew_create: "user/crew/create",
    }),
  },
  methods: {
    create() {
      this.$store.dispatch({ type: "user/crew/create" }).then(() => {
        this.$store.dispatch({ type: "refresh" });
      });
    },
    update() {
      if (!confirm(this.$t("profile.edit.crew.select.confirm"))) {
        return false;
      }

      if (this.current.length == 0) {
        this.$store.dispatch({ type: "user/crew/delete" });
      } else {
        this.$store.dispatch({ type: "user/crew/delete" }).then(() => {
          this.create();
        });
      }
    },
    leave() {
      if (!confirm(this.$t("profile.edit.crew.select.confirm"))) {
        return false;
      }
      this.$store.dispatch({ type: "user/crew/delete" }).then(() => {
        this.select = [];
        this.$store.dispatch({ type: "refresh" });
      });
    },
  },
  created() {
    this.$store.dispatch({ type: "crews/public" }).then().catch();
  },
};
</script>
