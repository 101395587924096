<template>
  <div class="vca-input">
    <vca-dropdown
      :label="label"
      :errorMsg="errorMsg"
      v-model="current"
      @input="change"
      :placeholder="placeholder"
      ref="reference"
      :rules="rules"
      :options="options"
    />
  </div>
</template>
<script>
export default {
  name: "CountrySelection",
  props: {
    rules: {
      type: Object,
      default: null,
    },
    placeholder: {
      type: String,
      default: "Default placeholder",
    },
    label: {
      type: String,
      default: "Default label",
    },
    value: {
      type: Array,
    },
    preselection: {
      type: String,
      default: null,
    },
    errorMsg: {
      type: String,
      default: "Error",
    },
  },
  created() {
    if (this.preselection !== null) {
      var value = this.options.find(
        (element) => element.value == this.preselection
      );
      if (value != null) {
        this.current = [value];
        this.$emit("input", this.current);
      }
    }
  },
  watch: {
    preselection: function (nVal) {
      if (nVal !== null) {
        var value = this.options.find((element) => element.value == nVal);
        if (value != null) {
          this.current = [value];
          this.$emit("input", this.current);
        }
      }
    },
  },
  methods: {
    change(e) {
      this.current = e;
      this.$emit("input", this.current);
      if (this.rules) {
        this.$refs.reference.validate();
      }
    },
    validate() {
      if (this.rules) {
        this.$refs.reference.validate();
      }
    },
  },
  data() {
    return {
      current: this.value,
      options: [
        {
          title: this.$t("country.afghanistan"),
          label: this.$t("country.afghanistan"),
          value: "AF",
        },
        {
          title: this.$t("country.aland_islands"),
          label: this.$t("country.aland_islands"),
          value: "AX",
        },
        {
          title: this.$t("country.albania"),
          label: this.$t("country.albania"),
          value: "AL",
        },
        {
          title: this.$t("country.algeria"),
          label: this.$t("country.algeria"),
          value: "DZ",
        },
        {
          title: this.$t("country.american_samoa"),
          label: this.$t("country.american samoa"),
          value: "AS",
        },
        {
          title: this.$t("country.andorra"),
          label: this.$t("country.andorra"),
          value: "AD",
        },
        {
          title: this.$t("country.angola"),
          label: this.$t("country.angola"),
          value: "AO",
        },
        {
          title: this.$t("country.anguilla"),
          label: this.$t("country.anguilla"),
          value: "AI",
        },
        {
          title: this.$t("country.antarctica"),
          label: this.$t("country.antarctica"),
          value: "AQ",
        },
        {
          title: this.$t("country.antigua_and_barbuda"),
          label: this.$t("country.antigua_and_barbuda"),
          value: "AG",
        },
        {
          title: this.$t("country.argentina"),
          label: this.$t("country.argentina"),
          value: "AR",
        },
        {
          title: this.$t("country.armenia"),
          label: this.$t("country.armenia"),
          value: "AM",
        },
        {
          title: this.$t("country.aruba"),
          label: this.$t("country.aruba"),
          value: "AW",
        },
        {
          title: this.$t("country.australia"),
          label: this.$t("country.australia"),
          value: "AU",
        },
        {
          title: this.$t("country.austria"),
          label: this.$t("country.austria"),
          value: "AT",
        },
        {
          title: this.$t("country.azerbaijan"),
          label: this.$t("country.azerbaijan"),
          value: "AZ",
        },
        {
          title: this.$t("country.bahamas"),
          label: this.$t("country.bahamas"),
          value: "BS",
        },
        {
          title: this.$t("country.bahrain"),
          label: this.$t("country.bahrain"),
          value: "BH",
        },
        {
          title: this.$t("country.bangladesh"),
          label: this.$t("country.bangladesh"),
          value: "BD",
        },
        {
          title: this.$t("country.barbados"),
          label: this.$t("country.barbados"),
          value: "BB",
        },
        {
          title: this.$t("country.belarus"),
          label: this.$t("country.belarus"),
          value: "BY",
        },
        {
          title: this.$t("country.belgium"),
          label: this.$t("country.belgium"),
          value: "BE",
        },
        {
          title: this.$t("country.belize"),
          label: this.$t("country.belize"),
          value: "BZ",
        },
        {
          title: this.$t("country.benin"),
          label: this.$t("country.benin"),
          value: "BJ",
        },
        {
          title: this.$t("country.bermuda"),
          label: this.$t("country.bermuda"),
          value: "BM",
        },
        {
          title: this.$t("country.bhutan"),
          label: this.$t("country.bhutan"),
          value: "BT",
        },
        {
          title: this.$t("country.bolivia"),
          label: this.$t("country.bolivia"),
          value: "BO",
        },
        {
          title: this.$t("country.bosnia_and_Herzegovina"),
          label: this.$t("country.bosnia_and_Herzegovina"),
          value: "BA",
        },
        {
          title: this.$t("country.botswana"),
          label: this.$t("country.botswana"),
          value: "BW",
        },
        {
          title: this.$t("country.bouvet_island"),
          label: this.$t("country.bouvet_island"),
          value: "BV",
        },
        {
          title: this.$t("country.brazil"),
          label: this.$t("country.brazil"),
          value: "BR",
        },
        {
          title: this.$t("country.british_indian_ocean_territory"),
          label: this.$t("country.british_indian_ocean_territory"),
          value: "IO",
        },
        {
          title: this.$t("country.brunei_darussalam"),
          label: this.$t("country.brunei_darussalam"),
          value: "BN",
        },
        {
          title: this.$t("country.bulgaria"),
          label: this.$t("country.bulgaria"),
          value: "BG",
        },
        {
          title: this.$t("country.burkina_faso"),
          label: this.$t("country.burkina_faso"),
          value: "BF",
        },
        {
          title: this.$t("country.burundi"),
          label: this.$t("country.burundi"),
          value: "BI",
        },
        {
          title: this.$t("country.cambodia"),
          label: this.$t("country.cambodia"),
          value: "KH",
        },
        {
          title: this.$t("country.cameroon"),
          label: this.$t("country.cameroon"),
          value: "CM",
        },
        {
          title: this.$t("country.canada"),
          label: this.$t("country.canada"),
          value: "CA",
        },
        {
          title: this.$t("country.cape_verde"),
          label: this.$t("country.cape_verde"),
          value: "CV",
        },
        {
          title: this.$t("country.cayman_islands"),
          label: this.$t("country.cayman_islands"),
          value: "KY",
        },
        {
          title: this.$t("country.central_african_republic"),
          label: this.$t("country.central_african_republic"),
          value: "CF",
        },
        {
          title: this.$t("country.chad"),
          label: this.$t("country.chad"),
          value: "TD",
        },
        {
          title: this.$t("country.chile"),
          label: this.$t("country.chile"),
          value: "CL",
        },
        {
          title: this.$t("country.china"),
          label: this.$t("country.china"),
          value: "CN",
        },
        {
          title: this.$t("country.christmas_island"),
          label: this.$t("country.christmas_island"),
          value: "CX",
        },
        {
          title: this.$t("country.cocos_islands"),
          label: this.$t("country.cocos_islands"),
          value: "CC",
        },
        {
          title: this.$t("country.colombia"),
          label: this.$t("country.colombia"),
          value: "CO",
        },
        {
          title: this.$t("country.comoros"),
          label: this.$t("country.comoros"),
          value: "KM",
        },
        {
          title: this.$t("country.congo"),
          label: this.$t("country.congo"),
          value: "CG",
        },
        {
          title: this.$t("country.congo_republic"),
          label: this.$t("country.congo_republic"),
          value: "CD",
        },
        {
          title: this.$t("country.cook_islands"),
          label: this.$t("country.cook_islands"),
          value: "CK",
        },
        {
          title: this.$t("country.costa_rica"),
          label: this.$t("country.costa_rica"),
          value: "CR",
        },
        {
          title: this.$t("country.cote_d_ivoire"),
          label: this.$t("country.cote_d_ivoire"),
          value: "CI",
        },
        {
          title: this.$t("country.croatia"),
          label: this.$t("country.croatia"),
          value: "HR",
        },
        {
          title: this.$t("country.cuba"),
          label: this.$t("country.cuba"),
          value: "CU",
        },
        {
          title: this.$t("country.cyprus"),
          label: this.$t("country.cyprus"),
          value: "CY",
        },
        {
          title: this.$t("country.czech_republic"),
          label: this.$t("country.czech_republic"),
          value: "CZ",
        },
        {
          title: this.$t("country.denmark"),
          label: this.$t("country.denmark"),
          value: "DK",
        },
        {
          title: this.$t("country.djibouti"),
          label: this.$t("country.djibouti"),
          value: "DJ",
        },
        {
          title: this.$t("country.dominica"),
          label: this.$t("country.dominica"),
          value: "DM",
        },
        {
          title: this.$t("country.dominican_republic"),
          label: this.$t("country.dominican_republic"),
          value: "DO",
        },
        {
          title: this.$t("country.ecuador"),
          label: this.$t("country.ecuador"),
          value: "EC",
        },
        {
          title: this.$t("country.egypt"),
          label: this.$t("country.egypt"),
          value: "EG",
        },
        {
          title: this.$t("country.el_salvador"),
          label: this.$t("country.el_salvador"),
          value: "SV",
        },
        {
          title: this.$t("country.equatorial_guinea"),
          label: this.$t("country.equatorial_guinea"),
          value: "GQ",
        },
        {
          title: this.$t("country.eritrea"),
          label: this.$t("country.eritrea"),
          value: "ER",
        },
        {
          title: this.$t("country.estonia"),
          label: this.$t("country.estonia"),
          value: "EE",
        },
        {
          title: this.$t("country.ethiopia"),
          label: this.$t("country.ethiopia"),
          value: "ET",
        },
        {
          title: this.$t("country.falkland_islands"),
          label: this.$t("country.falkland_islands"),
          value: "FK",
        },
        {
          title: this.$t("country.faroe_islands"),
          label: this.$t("country.faroe_islands"),
          value: "FO",
        },
        {
          title: this.$t("country.fiji"),
          label: this.$t("country.fiji"),
          value: "FJ",
        },
        {
          title: this.$t("country.finland"),
          label: this.$t("country.finland"),
          value: "FI",
        },
        {
          title: this.$t("country.france"),
          label: this.$t("country.france"),
          value: "FR",
        },
        {
          title: this.$t("country.french_guiana"),
          label: this.$t("country.french_guiana"),
          value: "GF",
        },
        {
          title: this.$t("country.french_polynesia"),
          label: this.$t("country.french_polynesia"),
          value: "PF",
        },
        {
          title: this.$t("country.french_southern_territories"),
          label: this.$t("country.french_southern_territories"),
          value: "TF",
        },
        {
          title: this.$t("country.gabon"),
          label: this.$t("country.gabon"),
          value: "GA",
        },
        {
          title: this.$t("country.gambia"),
          label: this.$t("country.gambia"),
          value: "GM",
        },
        {
          title: this.$t("country.georgia"),
          label: this.$t("country.georgia"),
          value: "GE",
        },
        {
          title: this.$t("country.germany"),
          label: this.$t("country.germany"),
          value: "DE",
        },
        {
          title: this.$t("country.ghana"),
          label: this.$t("country.ghana"),
          value: "GH",
        },
        {
          title: this.$t("country.gibraltar"),
          label: this.$t("country.gibraltar"),
          value: "GI",
        },
        {
          title: this.$t("country.greece"),
          label: this.$t("country.greece"),
          value: "GR",
        },
        {
          title: this.$t("country.greenland"),
          label: this.$t("country.greenland"),
          value: "GL",
        },
        {
          title: this.$t("country.grenada"),
          label: this.$t("country.grenada"),
          value: "GD",
        },
        {
          title: this.$t("country.guadeloupe"),
          label: this.$t("country.guadeloupe"),
          value: "GP",
        },
        {
          title: this.$t("country.guam"),
          label: this.$t("country.guam"),
          value: "GU",
        },
        {
          title: this.$t("country.guatemala"),
          label: this.$t("country.guatemala"),
          value: "GT",
        },
        {
          title: this.$t("country.guernsey"),
          label: this.$t("country.guernsey"),
          value: "GG",
        },
        {
          title: this.$t("country.guinea"),
          label: this.$t("country.guinea"),
          value: "GN",
        },
        {
          title: this.$t("country.guinea_bissau"),
          label: this.$t("country.guinea_bissau"),
          value: "GW",
        },
        {
          title: this.$t("country.guyana"),
          label: this.$t("country.guyana"),
          value: "GY",
        },
        {
          title: this.$t("country.haiti"),
          label: this.$t("country.haiti"),
          value: "HT",
        },
        {
          title: this.$t("country.heard_island_and_mcdonald_islands"),
          label: this.$t("country.heard_island_and_mcdonald_islands"),
          value: "HM",
        },
        {
          title: this.$t("country.honduras"),
          label: this.$t("country.honduras"),
          value: "HN",
        },
        {
          title: this.$t("country.hong_kong"),
          label: this.$t("country.hong_kong"),
          value: "HK",
        },
        {
          title: this.$t("country.hungary"),
          label: this.$t("country.hungary"),
          value: "HU",
        },
        {
          title: this.$t("country.iceland"),
          label: this.$t("country.iceland"),
          value: "IS",
        },
        {
          title: this.$t("country.india"),
          label: this.$t("country.india"),
          value: "IN",
        },
        {
          title: this.$t("country.indonesia"),
          label: this.$t("country.indonesia"),
          value: "ID",
        },
        {
          title: this.$t("country.iran"),
          label: this.$t("country.iran"),
          value: "IR",
        },
        {
          title: this.$t("country.iraq"),
          label: this.$t("country.iraq"),
          value: "IQ",
        },
        {
          title: this.$t("country.ireland"),
          label: this.$t("country.ireland"),
          value: "IE",
        },
        {
          title: this.$t("country.isle_of_man"),
          label: this.$t("country.isle_of_man"),
          value: "IM",
        },
        {
          title: this.$t("country.israel"),
          label: this.$t("country.israel"),
          value: "IL",
        },
        {
          title: this.$t("country.italy"),
          label: this.$t("country.italy"),
          value: "IT",
        },
        {
          title: this.$t("country.jamaica"),
          label: this.$t("country.jamaica"),
          value: "JM",
        },
        {
          title: this.$t("country.japan"),
          label: this.$t("country.japan"),
          value: "JP",
        },
        {
          title: this.$t("country.jersey"),
          label: this.$t("country.jersey"),
          value: "JE",
        },
        {
          title: this.$t("country.jordan"),
          label: this.$t("country.jordan"),
          value: "JO",
        },
        {
          title: this.$t("country.kazakhstan"),
          label: this.$t("country.kazakhstan"),
          value: "KZ",
        },
        {
          title: this.$t("country.kenya"),
          label: this.$t("country.kenya"),
          value: "KE",
        },
        {
          title: this.$t("country.kiribati"),
          label: this.$t("country.kiribati"),
          value: "KI",
        },
        {
          title: this.$t("country.korea_south"),
          label: this.$t("country.korea_south"),
          value: "KP",
        },
        {
          title: this.$t("country.korea_north"),
          label: this.$t("country.korea_north"),
          value: "KR",
        },
        {
          title: this.$t("country.kuwait"),
          label: this.$t("country.kuwait"),
          value: "KW",
        },
        {
          title: this.$t("country.kyrgyzstan"),
          label: this.$t("country.kyrgyzstan"),
          value: "KG",
        },
        {
          title: this.$t("country.laos"),
          label: this.$t("country.laos"),
          value: "LA",
        },
        {
          title: this.$t("country.latvia"),
          label: this.$t("country.latvia"),
          value: "LV",
        },
        {
          title: this.$t("country.lebanon"),
          label: this.$t("country.lebanon"),
          value: "LB",
        },
        {
          title: this.$t("country.lesotho"),
          label: this.$t("country.lesotho"),
          value: "LS",
        },
        {
          title: this.$t("country.liberia"),
          label: this.$t("country.liberia"),
          value: "LR",
        },
        {
          title: this.$t("country.libyan"),
          label: this.$t("country.libyan"),
          value: "LY",
        },
        {
          title: this.$t("country.liechtenstein"),
          label: this.$t("country.liechtenstein"),
          value: "LI",
        },
        {
          title: this.$t("country.lithuania"),
          label: this.$t("country.lithuania"),
          value: "LT",
        },
        {
          title: this.$t("country.luxembourg"),
          label: this.$t("country.luxembourg"),
          value: "LU",
        },
        {
          title: this.$t("country.macao"),
          label: this.$t("country.macao"),
          value: "MO",
        },
        {
          title: this.$t("country.macedonia"),
          label: this.$t("country.macedonia"),
          value: "MK",
        },
        {
          title: this.$t("country.madagascar"),
          label: this.$t("country.madagascar"),
          value: "MG",
        },
        {
          title: this.$t("country.malawi"),
          label: this.$t("country.malawi"),
          value: "MW",
        },
        {
          title: this.$t("country.malaysia"),
          label: this.$t("country.malaysia"),
          value: "MY",
        },
        {
          title: this.$t("country.maldives"),
          label: this.$t("country.maldives"),
          value: "MV",
        },
        {
          title: this.$t("country.mali"),
          label: this.$t("country.mali"),
          value: "ML",
        },
        {
          title: this.$t("country.malta"),
          label: this.$t("country.malta"),
          value: "MT",
        },
        {
          title: this.$t("country.marshall_islands"),
          label: this.$t("country.marshall_islands"),
          value: "MH",
        },
        {
          title: this.$t("country.martinique"),
          label: this.$t("country.martinique"),
          value: "MQ",
        },
        {
          title: this.$t("country.mauritania"),
          label: this.$t("country.mauritania"),
          value: "MR",
        },
        {
          title: this.$t("country.mauritius"),
          label: this.$t("country.mauritius"),
          value: "MU",
        },
        {
          title: this.$t("country.mayotte"),
          label: this.$t("country.mayotte"),
          value: "YT",
        },
        {
          title: this.$t("country.mexico"),
          label: this.$t("country.mexico"),
          value: "MX",
        },
        {
          title: this.$t("country.micronesia"),
          label: this.$t("country.micronesia"),
          value: "FM",
        },
        {
          title: this.$t("country.moldova"),
          label: this.$t("country.moldova"),
          value: "MD",
        },
        {
          title: this.$t("country.monaco"),
          label: this.$t("country.monaco"),
          value: "MC",
        },
        {
          title: this.$t("country.mongolia"),
          label: this.$t("country.mongolia"),
          value: "MN",
        },
        {
          title: this.$t("country.montserrat"),
          label: this.$t("country.montserrat"),
          value: "MS",
        },
        {
          title: this.$t("country.morocco"),
          label: this.$t("country.morocco"),
          value: "MA",
        },
        {
          title: this.$t("country.mozambique"),
          label: this.$t("country.mozambique"),
          value: "MZ",
        },
        {
          title: this.$t("country.myanmar"),
          label: this.$t("country.myanmar"),
          value: "MM",
        },
        {
          title: this.$t("country.namibia"),
          label: this.$t("country.namibia"),
          value: "NA",
        },
        {
          title: this.$t("country.nauru"),
          label: this.$t("country.nauru"),
          value: "NR",
        },
        {
          title: this.$t("country.nepal"),
          label: this.$t("country.nepal"),
          value: "NP",
        },
        {
          title: this.$t("country.netherlands"),
          label: this.$t("country.netherlands"),
          value: "NL",
        },
        {
          title: this.$t("country.netherlands_antilles"),
          label: this.$t("country.netherlands_antilles"),
          value: "AN",
        },
        {
          title: this.$t("country.new_caledonia"),
          label: this.$t("country.new_caledonia"),
          value: "NC",
        },
        {
          title: this.$t("country.new_zealand"),
          label: this.$t("country.new_zealand"),
          value: "NZ",
        },
        {
          title: this.$t("country.nicaragua"),
          label: this.$t("country.nicaragua"),
          value: "NI",
        },
        {
          title: this.$t("country.niger"),
          label: this.$t("country.niger"),
          value: "NE",
        },
        {
          title: this.$t("country.nigeria"),
          label: this.$t("country.nigeria"),
          value: "NG",
        },
        {
          title: this.$t("country.niue"),
          label: this.$t("country.niue"),
          value: "NU",
        },
        {
          title: this.$t("country.norfolk_island"),
          label: this.$t("country.norfolk_island"),
          value: "NF",
        },
        {
          title: this.$t("country.northern_mariana_islands"),
          label: this.$t("country.northern_mariana_islands"),
          value: "MP",
        },
        {
          title: this.$t("country.norway"),
          label: this.$t("country.norway"),
          value: "NO",
        },
        {
          title: this.$t("country.oman"),
          label: this.$t("country.oman"),
          value: "OM",
        },
        {
          title: this.$t("country.pakistan"),
          label: this.$t("country.pakistan"),
          value: "PK",
        },
        {
          title: this.$t("country.palau"),
          label: this.$t("country.palau"),
          value: "PW",
        },
        {
          title: this.$t("country.palestinian_territory"),
          label: this.$t("country.palestinian_territory"),
          value: "PS",
        },
        {
          title: this.$t("country.panama"),
          label: this.$t("country.panama"),
          value: "PA",
        },
        {
          title: this.$t("country.papua_new_guinea"),
          label: this.$t("country.papua_new_guinea"),
          value: "PG",
        },
        {
          title: this.$t("country.paraguay"),
          label: this.$t("country.paraguay"),
          value: "PY",
        },
        {
          title: this.$t("country.peru"),
          label: this.$t("country.peru"),
          value: "PE",
        },
        {
          title: this.$t("country.philippines"),
          label: this.$t("country.philippines"),
          value: "PH",
        },
        {
          title: this.$t("country.pitcairn"),
          label: this.$t("country.pitcairn"),
          value: "PN",
        },
        {
          title: this.$t("country.poland"),
          label: this.$t("country.poland"),
          value: "PL",
        },
        {
          title: this.$t("country.portugal"),
          label: this.$t("country.portugal"),
          value: "PT",
        },
        {
          title: this.$t("country.puerto_rico"),
          label: this.$t("country.puerto_rico"),
          value: "PR",
        },
        {
          title: this.$t("country.qatar"),
          label: this.$t("country.qatar"),
          value: "QA",
        },
        {
          title: this.$t("country.reunion"),
          label: this.$t("country.reunion"),
          value: "RE",
        },
        {
          title: this.$t("country.romania"),
          label: this.$t("country.romania"),
          value: "RO",
        },
        {
          title: this.$t("country.russian_federation"),
          label: this.$t("country.russian_federation"),
          value: "RU",
        },
        {
          title: this.$t("country.rwanda"),
          label: this.$t("country.rwanda"),
          value: "RW",
        },
        {
          title: this.$t("country.saint_helena"),
          label: this.$t("country.saint_helena"),
          value: "SH",
        },
        {
          title: this.$t("country.saint_kitts_and_nevis"),
          label: this.$t("country.saint_kitts_and_nevis"),
          value: "KN",
        },
        {
          title: this.$t("country.saint_lucia"),
          label: this.$t("country.saint_lucia"),
          value: "LC",
        },
        {
          title: this.$t("country.saint_pierre_and_miquelon"),
          label: this.$t("country.saint_pierre_and_miquelon"),
          value: "PM",
        },
        {
          title: this.$t("country.saint_vincent_and_the_grenadines"),
          label: this.$t("country.saint_vincent_and_the_grenadines"),
          value: "VC",
        },
        {
          title: this.$t("country.samoa"),
          label: this.$t("country.samoa"),
          value: "WS",
        },
        {
          title: this.$t("country.san_marino"),
          label: this.$t("country.san_marino"),
          value: "SM",
        },
        {
          title: this.$t("country.sao_tome_and_principe"),
          label: this.$t("country.sao_tome_and_principe"),
          value: "ST",
        },
        {
          title: this.$t("country.saudi_arabia"),
          label: this.$t("country.saudi_arabia"),
          value: "SA",
        },
        {
          title: this.$t("country.senegal"),
          label: this.$t("country.senegal"),
          value: "SN",
        },
        {
          title: this.$t("country.serbia_and_montenegro"),
          label: this.$t("country.serbia_and_montenegro"),
          value: "CS",
        },
        {
          title: this.$t("country.seychelles"),
          label: this.$t("country.seychelles"),
          value: "SC",
        },
        {
          title: this.$t("country.sierra_leone"),
          label: this.$t("country.sierra_leone"),
          value: "SL",
        },
        {
          title: this.$t("country.singapore"),
          label: this.$t("country.singapore"),
          value: "SG",
        },
        {
          title: this.$t("country.slovakia"),
          label: this.$t("country.slovakia"),
          value: "SK",
        },
        {
          title: this.$t("country.slovenia"),
          label: this.$t("country.slovenia"),
          value: "SI",
        },
        {
          title: this.$t("country.solomon_islands"),
          label: this.$t("country.solomon_islands"),
          value: "SB",
        },
        {
          title: this.$t("country.somalia"),
          label: this.$t("country.somalia"),
          value: "SO",
        },
        {
          title: this.$t("country.south_africa"),
          label: this.$t("country.south_africa"),
          value: "ZA",
        },
        {
          title: this.$t(
            "country.south_georgia_and_the_south_sandwich_islands"
          ),
          label: this.$t(
            "country.south_georgia_and_the_south_sandwich_islands"
          ),
          value: "GS",
        },
        {
          title: this.$t("country.spain"),
          label: this.$t("country.spain"),
          value: "ES",
        },
        {
          title: this.$t("country.sri_lanka"),
          label: this.$t("country.sri_lanka"),
          value: "LK",
        },
        {
          title: this.$t("country.sudan"),
          label: this.$t("country.sudan"),
          value: "SD",
        },
        {
          title: this.$t("country.south_sudan"),
          label: this.$t("country.south_sudan"),
          value: "SS",
        },
        {
          title: this.$t("country.suriname"),
          label: this.$t("country.suriname"),
          value: "SR",
        },
        {
          title: this.$t("country.svalbard_and_jan_mayen"),
          label: this.$t("country.svalbard_and_jan_mayen"),
          value: "SJ",
        },
        {
          title: this.$t("country.swaziland"),
          label: this.$t("country.swaziland"),
          value: "SZ",
        },
        {
          title: this.$t("country.sweden"),
          label: this.$t("country.sweden"),
          value: "SE",
        },
        {
          title: this.$t("country.switzerland"),
          label: this.$t("country.switzerland"),
          value: "CH",
        },
        {
          title: this.$t("country.syria"),
          label: this.$t("country.syria"),
          value: "SY",
        },
        {
          title: this.$t("country.taiwan"),
          label: this.$t("country.taiwan"),
          value: "TW",
        },
        {
          title: this.$t("country.tajikistan"),
          label: this.$t("country.tajikistan"),
          value: "TJ",
        },
        {
          title: this.$t("country.tanzania"),
          label: this.$t("country.tanzania"),
          value: "TZ",
        },
        {
          title: this.$t("country.thailand"),
          label: this.$t("country.thailand"),
          value: "TH",
        },
        {
          title: this.$t("country.timor_leste"),
          label: this.$t("country.timor_leste"),
          value: "TL",
        },
        {
          title: this.$t("country.togo"),
          label: this.$t("country.togo"),
          value: "TG",
        },
        {
          title: this.$t("country.tokelau"),
          label: this.$t("country.tokelau"),
          value: "TK",
        },
        {
          title: this.$t("country.tonga"),
          label: this.$t("country.tonga"),
          value: "TO",
        },
        {
          title: this.$t("country.trinidad_and_tobago"),
          label: this.$t("country.trinidad_and_tobago"),
          value: "TT",
        },
        {
          title: this.$t("country.tunisia"),
          label: this.$t("country.tunisia"),
          value: "TN",
        },
        {
          title: this.$t("country.turkey"),
          label: this.$t("country.turkey"),
          value: "TR",
        },
        {
          title: this.$t("country.turkmenistan"),
          label: this.$t("country.turkmenistan"),
          value: "TM",
        },
        {
          title: this.$t("country.turks_and_caicos_islands"),
          label: this.$t("country.turks_and_caicos_islands"),
          value: "TC",
        },
        {
          title: this.$t("country.tuvalu"),
          label: this.$t("country.tuvalu"),
          value: "TV",
        },
        {
          title: this.$t("country.uganda"),
          label: this.$t("country.uganda"),
          value: "UG",
        },
        {
          title: this.$t("country.ukraine"),
          label: this.$t("country.ukraine"),
          value: "UA",
        },
        {
          title: this.$t("country.united_arab_emirates"),
          label: this.$t("country.united_arab_emirates"),
          value: "AE",
        },
        {
          title: this.$t("country.united_kingdom"),
          label: this.$t("country.united_kingdom"),
          value: "GB",
        },
        {
          title: this.$t("country.united_states"),
          label: this.$t("country.united_states"),
          value: "US",
        },
        {
          title: this.$t("country.united_states_minor_outlying_islands"),
          label: this.$t("country.united_states_minor_outlying_islands"),
          value: "UM",
        },
        {
          title: this.$t("country.united_states_virgin_islands"),
          label: this.$t("country.united_states_virgin_islands"),
          value: "VI",
        },
        {
          title: this.$t("country.uruguay"),
          label: this.$t("country.uruguay"),
          value: "UY",
        },
        {
          title: this.$t("country.uzbekistan"),
          label: this.$t("country.uzbekistan"),
          value: "UZ",
        },
        {
          title: this.$t("country.vanuatu"),
          label: this.$t("country.vanuatu"),
          value: "VU",
        },
        {
          title: this.$t("country.venezuela"),
          label: this.$t("country.venezuela"),
          value: "VE",
        },
        {
          title: this.$t("country.vatican"),
          label: this.$t("country.vatican"),
          value: "VA",
        },
        {
          title: this.$t("country.vietnam"),
          label: this.$t("country.vietnam"),
          value: "VN",
        },
        {
          title: this.$t("country.british_virgin_islands"),
          label: this.$t("country.british_virgin_islands"),
          value: "VG",
        },
        {
          title: this.$t("country.wallis_and_futuna"),
          label: this.$t("country.wallis_and_futuna"),
          value: "WF",
        },
        {
          title: this.$t("country.western_sahara"),
          label: this.$t("country.western_sahara"),
          value: "EH",
        },
        {
          title: this.$t("country.yemen"),
          label: this.$t("country.yemen"),
          value: "YE",
        },
        {
          title: this.$t("country.zambia"),
          label: this.$t("country.zambia"),
          value: "ZM",
        },
        {
          title: this.$t("country.zimbabwe"),
          label: this.$t("country.zimbabwe"),
          value: "ZW",
        },
      ],
    };
  },
};
</script>
