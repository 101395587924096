<template>
  <div>
    <h3>{{ $t("profile.newsletter.header") }}</h3>
    <vca-row>
      <vca-checkbox
        :disabled="!hasCrew"
        v-model="edit_list"
        id="regional"
        @input="process('regional')"
      >
        {{ $t("profile.newsletter.regional.title") }}
      </vca-checkbox>
      <vca-checkbox v-model="edit_list" id="global" @input="process('global')">
        {{ $t("profile.newsletter.global.title") }}
      </vca-checkbox>
    </vca-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "NewsletterSelection",
  computed: {
    edit_list: {
      get() {
        return this.$store.state.user.newsletter.edit_list;
      },
      set(value) {
        this.$store.commit("user/newsletter/edit_list", value);
      },
    },
    ...mapGetters({
      current_list: "user/newsletter/current_list",
      hasCrew: "user/crew/hasCrew",
    }),
  },
  methods: {
    process(value) {
      if (this.current_list.includes(value)) {
        this.$store
          .dispatch({
            type: "user/newsletter/delete",
            data: {
              value,
            },
          })
          .then(() => {
            this.$store.dispatch("refresh");
          });
      } else {
        this.$store
          .dispatch({
            type: "user/newsletter/create",
            data: {
              value,
            },
          })
          .then(() => {
            this.$store.dispatch("refresh");
          });
      }
    },
  },
};
</script>
