<template>
  <vca-card class="shadowed">
    <p v-if="filename == ''">
      {{ $t("profile.edit.general.avatar.upload") }}
    </p>
    <div class="vca-center preview"><img id="preview" /></div>
    <div class="desktop-view">
      <vca-row>
        <label
          for="avatarFile"
          class="text-center vca-button-small vca-file-input-label"
          >{{ currentFile }}</label
        >
        <IconButton
          v-if="filename != ''"
          type="cancel"
          @click="resetFile"
          :title="$t('button.clear')"
        />
      </vca-row>
    </div>
    <div class="mobile-view">
      <vca-column class="text-center">
        <label
          for="avatarFile"
          class="text-center vca-button-small vca-file-input-label"
          >{{ currentFile }}</label
        >
        <vca-cancel-button
          v-if="filename != ''"
          @click="resetFile"
          :placeholder="$t('button.remove')"
          class="vca-file-input-label"
        />
      </vca-column>
    </div>

    <input
      @change="fileChange"
      :accept="allowedFiles"
      type="file"
      id="avatarFile"
      class="vca-file-input"
      name="avatar"
    />
    <div>
      <button v-if="filename != ''" class="vca-button" @click="upload()">
        {{ $t("button.upload") }}
      </button>
    </div>
  </vca-card>
</template>

<script>
import IconButton from "@/components/utils/IconButton";
export default {
  name: "AvatarUpload",
  components: { IconButton },
  data() {
    return {
      filename: "",
      file: null,
      allowedFiles: ".jpg, .jpeg, .png, .gif",
    };
  },
  methods: {
    dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);
      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type: mimeString });
    },
    upload() {
      var formData = new FormData();
      var preview = document.querySelector("#preview");
      var blob = this.dataURItoBlob(preview.src);
      formData.append("file", new File([blob], this.filename, blob));
      this.$store.dispatch({
        type: "user/avatar/upload",
        data: formData,
      });
      this.resetFile();
    },
    fileChange(event) {
      this.file = null;
      var fileData = event.target.files[0];

      var lastDot = fileData.name.lastIndexOf(".");
      var ext = fileData.name.substring(lastDot + 1).toLowerCase();

      var allowed = this.allowedFiles.replace(/\./g, "").split(", ");
      var imageType = /^image\//;

      if (!allowed.includes(ext) || !imageType.test(fileData.type)) {
        this.notification({
          title: "messages.title.warn",
          body: this.$t("messages.error.wrong_extension", {
            0: this.allowedFiles,
          }),
          type: "warn",
        });
        this.resetFile();
        return;
      }
      var reader = new FileReader();
      reader.onload = function (e) {
        var img = document.createElement("img");
        img.onload = function () {
          var MAX_WIDTH = 400;
          var MAX_HEIGHT = 400;

          var width = img.width;
          var height = img.height;
          // Change the resizing logic
          if (width > height) {
            if (width > MAX_WIDTH) {
              height = height * (MAX_WIDTH / width);
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width = width * (MAX_HEIGHT / height);
              height = MAX_HEIGHT;
            }
          }

          // Dynamically create a canvas element
          var canvas = document.createElement("canvas");

          // var canvas = document.getElementById("canvas");
          var ctx = canvas.getContext("2d");
          canvas.width = width;
          canvas.height = height;
          // Actual resizing
          ctx.drawImage(img, 0, 0, width, height);

          // Show resized image in preview element
          var dataurl = canvas.toDataURL(fileData.type);
          document.getElementById("preview").src = dataurl;
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(fileData);

      this.filename = fileData.name;
    },
    resetFile() {
      var imagefile = document.querySelector("#avatarFile");
      imagefile.value = "";
      var preview = document.querySelector("#preview");
      preview.src = "";
      this.filename = "";
    },
  },
  computed: {
    currentFile() {
      return this.filename == "" ? this.$t("button.select") : this.filename;
    },
  },
};
</script>
<style lang="scss">
.preview {
  max-width: 100%;
  img {
    max-width: 100%;
  }
  @include media(large) {
    max-width: initial;
    img {
      max-width: initial;
    }
  }
}

.vca-file-input-label {
  display: inline-block;
  margin: 10px auto;
  width: max-content;
  @include media(large) {
    margin: 10px;
  }

  button {
    height: 100%;
    margin: auto;
  }
}

.vca-file-input {
  display: none;
}
</style>
